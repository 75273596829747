<template>
  <section v-if="!aadUserIsRegistered"><login-page></login-page></section>
  <section v-if="aadUserIsRegistered && activeTab !== 'print-log-entries'">
    <the-navbar
      @newActiveTab="setActiveTab"
      :activeTab="activeTab"
    ></the-navbar>
    <log-entries
      v-if="activeTab === 'log-entries'"
      :entries="entries"
      @getEntriesFromDb="getEntriesFromDb"
      @passToBeEditedEntryId="EditToBeEditedEntry"
    ></log-entries>
    <insert-entry
      v-if="activeTab === 'insert-entry'"
      :userFullName="this.userFullName"
      @getEntriesFromDb="getEntriesFromDb"
    ></insert-entry>
    <edit-entry
      v-if="activeTab === 'edit-entry'"
      :toBeEditedEntryObject="toBeEditedEntryObject"
      @getEntriesFromDb="getEntriesFromDb"
    ></edit-entry>
    <user-settings
      v-if="activeTab === 'user-settings'"
      :clientPrincipal="clientPrincipal"
      :userFullName="userFullName"
      @getUserAccountsFromDb="getUserAccountsFromDb"
    ></user-settings>
  </section>
  <section
    @click="activeTab = 'log-entries'"
    v-if="activeTab === 'print-log-entries'"
  >
    <print-log-entries
      :userFullName="userFullName"
      :entries="entries"
      @getUserAccountsFromDb="getUserAccountsFromDb"
    ></print-log-entries>
  </section>
</template>

<script>
import TheNavbar from './components/UI/TheNavbar.vue';
import InsertEntry from './components/InsertEntry.vue';
import EditEntry from './components/EditEntry.vue';
import LogEntries from './components/LogEntries.vue';
// import TheFooter from './components/UI/TheFooter.vue';
import PrintLogEntries from './components/PrintLogEntries.vue';
import LoginPage from './components/LoginPage.vue';
import UserSettings from './components/UserSettings.vue';

export default {
  name: 'App',
  components: {
    TheNavbar,
    LogEntries,
    InsertEntry,
    EditEntry,
    PrintLogEntries,
    LoginPage,
    UserSettings,
  },
  data() {
    return {
      clientPrincipal: null,
      aadUserId: null,
      aadUserRoles: null,
      aadUserDetails: null,
      aadUserRetrieveError: null,
      aadUserIsAdmin: false,
      aadUserIsRegistered: false,
      activeTab: 'log-entries',
      toBeEditedEntryObject: null,
      userFullName: null,
      userAccounts: [],
      entries: [],
    };
  },
  methods: {
    getEntriesFromDb() {
      fetch('/api/entries')
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Response not OK!');
          }
        })
        .then((data) => {
          this.entries = data.entries.reverse();
        })
        .catch((error) => {
          console.log('Catch event occurred!' + error);
        });
    },

    getAadUserInfo() {
      fetch('/.auth/me')
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.clientPrincipal = null;
            this.aadUserId = null;
            this.aadUserRoles = null;
            this.aadUserDetails = null;
            this.aadUserRetrieveError = null;
            this.aadUserIsAdmin = false;
            this.aadUserIsRegistered = false;
            console.log('Response not OK');
          }
        })
        .then((data) => {
          this.clientPrincipal = data.clientPrincipal;
          this.aadUserId = data.clientPrincipal.userId;
          this.aadUserRoles = data.clientPrincipal.userRoles;
          this.aadUserDetails = data.clientPrincipal.userDetails;
          this.aadUserIsAdmin = this.aadUserRoles.includes('admin');
          this.aadUserIsRegistered = this.aadUserRoles.includes('registered');
          // getUserAccountsFromDb();
        })
        .catch((error) => {
          this.clientPrincipal = null;
          this.aadUserId = null;
          this.aadUserRoles = null;
          this.aadUserDetails = null;
          this.aadUserRetrieveError = null;
          this.aadUserIsAdmin = false;
          this.aadUserIsRegistered = false;
          console.log('Catch event occurred!' + error);
        });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    EditToBeEditedEntry(toBeEditedEntryId) {
      const toBeEditedEntryObject = this.entries.find(
        (element) => element._id === toBeEditedEntryId
      );
      this.toBeEditedEntryObject = toBeEditedEntryObject;
      this.activeTab = 'edit-entry';
    },
    getUserAccountsFromDb() {
      fetch('/api/users')
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Response NOT ok!');
          }
        })
        .then((data) => {
          this.userAccounts = data.users;
          this.setUserFullName();
        })
        .catch((error) => {
          console.log('Catch event: ' + error);
        });
    },

    setUserFullName() {
      this.userFullName = this.userAccounts.find(
        (element) => element.aadUserId === this.aadUserId
      ).userFullName;
    },
  },

  created() {
    this.getAadUserInfo();
    this.getEntriesFromDb();
    this.getUserAccountsFromDb();
  },
};
</script>

<style>
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

body,
html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url('/w3images/mac.jpg');
  min-height: 100%;
}

.w3-bar .w3-button {
  padding: 16px;
}
</style>
