<template>
  <button class="{disabled: buttonsDisabled}">
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  width: 200px;
  background-color: rgb(105, 166, 209);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
}

button:hover,
button:active {
  background-color: #06b;
  background-color: #06b;
}

button:disabled {
  background-color: rgb(107, 107, 107);
}
</style>
