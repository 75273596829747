<template>
  <div class="w3-container" style="padding: 128px 16px" id="team">
    <div class="w3-row-padding" style="margin-top: 10px">
      <div style="width: 100%" class="w3-col l3 m6 w3-margin-bottom">
        <div class="w3-card">
          <div class="w3-container">
            <h3>Käyttäjän asetukset</h3>

            <p class="strong">Azure AD Application userId:</p>
            <p>
              {{ clientPrincipal.userId }}
            </p>
            <p class="strong">Roolit:</p>

            <p v-for="role in clientPrincipal.userRoles" :key="role">
              {{ role }}
            </p>

            <p class="strong">Azure AD userId:</p>
            <p>{{ clientPrincipal.userDetails }}</p>

            <p class="strong">Käyttäjän koko nimi:</p>
            <p>
              <input
                size="30"
                name="userFullNameInput"
                v-model="userFullNameInput"
              />
            </p>
            <div>
              <span v-if="!insertOk"><strong>&nbsp;</strong></span>
              <span v-if="insertOk" style="color: green"
                ><i class="fa fa-thumbs-up fa-3x"></i
                ><strong> TALLENNETTU!</strong></span
              >
              <span v-if="notOk" style="color: red"
                ><strong> Virhe - Tallentaminen epäonnistui!</strong></span
              >
            </div>
            <p>
              <base-button @click="saveUserFullNameToDb">TALLENNA</base-button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './UI/BaseButton.vue';

export default {
  components: { BaseButton },
  emits: ['getUserAccountsFromDb'],
  props: ['clientPrincipal', 'userFullName'],

  data() {
    return {
      aadUserId: this.clientPrincipal.userId,
      userFullNameInput: this.userFullName,
      insertOk: false,
      notOk: false,
    };
  },

  methods: {
    saveUserFullNameToDb() {
      const newUser = {
        aadUserId: this.aadUserId,
        userFullName: this.userFullNameInput,
      };
      console.log(newUser);
      fetch('/api/users', {
        method: 'PUT',
        body: JSON.stringify(newUser),
        headers: {
          'Content-Type': 'application/json', // indicate return type of JSON
        },
      })
        .then((response) => {
          if (response.status === 204) {
            console.log('Updated existing!');
            this.insertOk = true;
            this.notOk = false;
          } else if (response.status === 201) {
            console.log('Created a new user!');
            this.insertOk = true;
            this.notOk = false;
          } else {
            console.log('Response not OK');
            this.notOk;
            this.insertOk = false;
          }
        })
        .catch((error) => {
          console.log('Catch error: ' + error);
        });
      this.$emit('getUserAccountsFromDb');
    },
  },
  mounted() {
    this.$emit('getUserAccountsFromDb');
  },
};
</script>

<style scoped>
.strong {
  font-weight: bold;
}
</style>
