<template>
  <base-dialog v-if="confirmDeleteDialog" title="Vahvista poistaminen">
    <template #main>
      <p>
        {{ ddInput }}.{{ mmInput }}.{{ yyyyInput }} - {{ rataInput }} -
        {{ aseInput }} - {{ laukauksetInput }} - {{ muutaInput }}
      </p>
    </template>
    <template #actions>
      <delete-button @click="confirmDelete">POISTA</delete-button>
      <base-button @click="cancelDelete">PERU</base-button>
    </template>
  </base-dialog>

  <div class="w3-container" style="padding: 128px 16px" id="team">
    <div class="w3-row-padding" style="margin-top: 10px">
      <!-- <div style="width: 100%" class="w3-col l3 m6 w3-margin-bottom"> -->
      <div class="w3-card">
        <div class="w3-container" style="height: 1000px">
          <form>
            <h3>Muokkaa tapahtumaa</h3>
            <div class="input-container ic1">
              <select
                class="input"
                name="dd"
                id="dd"
                v-model.number="ddInput"
                :disabled="buttonsDisabled"
              >
                <option v-for="index in 31" :key="index">{{ index }}</option>
              </select>
              <div class="cut"></div>
              <label for="dd" class="placeholder">Päivä</label>
            </div>

            <div class="input-container ic1">
              <select
                class="input"
                name="mm"
                id="mm"
                v-model.number="mmInput"
                :disabled="buttonsDisabled"
              >
                <option v-for="index in 12" :key="index">{{ index }}</option>
              </select>
              <div class="cut"></div>
              <label for="dd" class="placeholder">Kuukausi</label>
            </div>

            <div class="input-container ic1">
              <select
                class="input"
                name="yyyy"
                id="yyyy"
                v-model.number="yyyyInput"
                :disabled="buttonsDisabled"
              >
                <option v-for="index in 10" :key="index">
                  {{ 2020 + index }}
                </option>
              </select>
              <div class="cut"></div>
              <label for="yyyy" class="placeholder">Vuosi</label>
            </div>

            <div class="input-container ic1">
              <select
                class="input"
                name="rata"
                id="rata"
                v-model="rataInput"
                :disabled="buttonsDisabled"
              >
                <option v-for="rata in radat" :key="rata">{{ rata }}</option>
              </select>
              <div class="cut"></div>
              <label for="rata" class="placeholder">Rata</label>
            </div>

            <div class="input-container ic1">
              <select
                class="input"
                name="ase"
                id="ase"
                v-model="aseInput"
                :disabled="buttonsDisabled"
              >
                <option v-for="ase in aseet" :key="ase">{{ ase }}</option>
              </select>
              <div class="cut"></div>
              <label for="ase" class="placeholder">Ase</label>
            </div>

            <div class="input-container ic1">
              <input
                id="laukaukset"
                class="input"
                type="number"
                v-model.number="laukauksetInput"
                :disabled="buttonsDisabled"
              />
              <div class="cut"></div>
              <label for="laukaukset" class="placeholder">Laukaukset</label>
            </div>

            <div class="input-container ic1">
              <input
                id="muuta"
                class="input"
                type="text"
                v-model="muutaInput"
                :disabled="buttonsDisabled"
              />
              <div class="cut"></div>
              <label for="muuta" class="placeholder">Muita huomioita</label>
            </div>
            <div>
              <span v-if="!updateOk && !deleteOk"><strong>&nbsp;</strong></span>
              <span v-if="deleteOk" style="color: green"
                ><i class="fa fa-thumbs-up fa-3x"></i
                ><strong> POISTETTU!</strong></span
              >
              <span v-if="updateOk" style="color: green"
                ><i class="fa fa-thumbs-up fa-3x"></i
                ><strong> PÄIVITETTY!</strong></span
              >
              <span v-if="notOk" style="color: red"
                ><strong> Virhe! Toimenpide epäonnistui!</strong></span
              >
            </div>
            <div class="input-container ic1">
              <base-button
                :disabled="buttonsDisabled"
                @click.prevent="updateEditedEntryToDb"
                ><i class="fa fa-pencil"></i> TALLENNA</base-button
              >
              <delete-button
                :disabled="buttonsDisabled"
                @click.prevent="confirmDeleteDialog = true"
                ><i class="fa fa-pencil"></i> POISTA</delete-button
              >
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import BaseButton from './UI/BaseButton.vue';
import BaseDialog from './UI/BaseDialog.vue';
import DeleteButton from './UI/DeleteButton.vue';

export default {
  components: { BaseButton, DeleteButton, BaseDialog },
  props: ['toBeEditedEntryObject'],
  emits: ['getEntriesFromDb'],
  data() {
    return {
      updateOk: false,
      notOk: false,
      confirmDeleteDialog: false,
      deleteOk: false,
      buttonsDisabled: false,
      ddInput: null,
      mmInput: null,
      yyyyInput: null,
      aseInput: this.toBeEditedEntryObject.ase,
      laukauksetInput: this.toBeEditedEntryObject.laukaukset,
      rataInput: this.toBeEditedEntryObject.rata,
      muutaInput: this.toBeEditedEntryObject.muuta,
      radat: [
        'Raasin ampumarata (Puolustusvoimat), Pöytyä',
        'Huovinrinteen ampumarata (Puolustusvoimat), Säkylä',
        'Hevonpään ampumarata (SaSa), Halikko',
        'Loimaan ampumarata (Loimaan Laukojat), Loimaa',
        'Temppelivuoren sisäampumarata (Turku-IPSC), Raisio',
        'Nuutin ampumarata (TSA), Turku',
      ],
      aseet: [
        'Pistooli 9x19mm, itselataava kertatuli (TT3)',
        'Haulikko 12kal, itselataava kertatuli (TT3)',
        'Kivääri .223, itselataava kertatuli (TT3)',
        'Kivääri 7,62x39mm, itselataava kertatuli (TT3)',
        'Kivääri 7,62x39mm, sarjatuli (TT4)',
        'Kivääri .308, lippaallinen kertatuli (TT2)',
        'Kivääri .30-06, lippaallinen kertatuli (TT2)',
        'Kivääri .308, kertatuli (TT1)',
        'Kivääri .30-06, kertatuli (TT1)',
      ],
    };
  },

  methods: {
    timeStringToComponents(dateString) {
      const dateObj = new Date(dateString);
      this.ddInput = dateObj.getDate();
      this.mmInput = dateObj.getMonth() + 1;
      this.yyyyInput = dateObj.getFullYear();
    },
    updateEditedEntryToDb() {
      const pvm = Date.parse(
        this.yyyyInput + '-' + this.mmInput + '-' + this.ddInput
      );
      const editedEntryObject = {
        pvm: pvm,
        rata: this.rataInput,
        ase: this.aseInput,
        laukaukset: this.laukauksetInput,
        muuta: this.muutaInput,
      };

      const fetchUrl = '/api/entries/' + this.toBeEditedEntryObject._id;
      fetch(fetchUrl, {
        method: 'PUT', // method to modify items
        body: JSON.stringify(editedEntryObject), // put task in body
        headers: {
          'Content-Type': 'application/json', // indicate return type of JSON
        },
      }).then((response) => {
        console.log(response);
        if (response.status === 204) {
          this.updateOk = true;
          this.buttonsDisabled = true;
          this.$emit('getEntriesFromDb');
        } else {
          console.log('Response not OK!');
          this.notOk = true;
        }
      });
    },

    markDeletedInDb() {
      this.confirmDelete = true;
      const toBeMarkedDeletedEntryObject = {
        muuta: this.muutaInput,
        deleted: true,
      };

      const fetchUrl = '/api/entries/' + this.toBeEditedEntryObject._id;
      fetch(fetchUrl, {
        method: 'PUT', // method to modify items
        body: JSON.stringify(toBeMarkedDeletedEntryObject), // put task in body
        headers: {
          'Content-Type': 'application/json', // indicate return type of JSON
        },
      }).then((response) => {
        console.log(response);
        if (response.status === 204) {
          this.deleteOk = true;
          this.buttonsDisabled = true;
          this.$emit('getEntriesFromDb');
        } else {
          console.log('Response not OK!');
          this.notOk = true;
        }
      });
    },
    confirmDelete() {
      this.markDeletedInDb();
      this.confirmDeleteDialog = false;
    },
    cancelDelete() {
      this.confirmDeleteDialog = false;
    },
  },
  mounted() {
    this.timeStringToComponents(this.toBeEditedEntryObject.pvm);
    this.$emit('getEntriesFromDb');
  },
};
</script>

<style scoped>
section {
  align-items: center;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  height: 100vh;
}
.form {
  background-color: #b1c2c277;
  border-radius: 20px;
  box-sizing: border-box;
}
.title {
  color: rgb(69, 60, 122);
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: rgb(69, 60, 122);
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #b7ccd8;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #b1c2c277;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 100px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #b1c2c277;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #000000;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: rgb(105, 166, 209);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}
</style>
