<template>
  <div>
    <header>
      <h2>Ampumapäiväkirja - {{ userFullName }}</h2>
      <h3></h3>
    </header>
    <table class="fl-table">
      <tr>
        <th>PVM</th>
        <th>Rata</th>
        <th>Ase</th>
        <th>Laukaukset</th>
        <th>Lisätietoja</th>
      </tr>
      <tr v-for="entry in entries" :key="entry.id">
        <td>{{ formatDate(entry.pvm) }}</td>
        <td>{{ entry.rata }}</td>
        <td>{{ entry.ase }}</td>
        <td>{{ entry.laukaukset }}</td>
        <td>{{ entry.muuta }}</td>
      </tr>
    </table>
  </div>
  <div></div>
  <div id="signature"></div>
  <p class="signaturetext">{{ userFullName }}</p>
  <p class="signaturetext">
    {{ today.getDate() }}.{{ today.getMonth() + 1 }}.{{ today.getFullYear() }}
  </p>
</template>

<script>
//import BaseCard from './UI/BaseCard.vue'
// import BaseButton from './UI/BaseButton.vue'
export default {
  // components: { BaseCard },
  props: ['entries', 'userFullName'],
  emits: ['getUserAccountsFromDb'],
  data() {
    return {
      today: new Date(),
    };
  },
  methods: {
    formatDate(dateString) {
      const dateObj = new Date(dateString);
      const formattedDateString =
        dateObj.getDate() +
        '.' +
        (dateObj.getMonth() + 1) +
        '.' +
        dateObj.getFullYear();
      return formattedDateString;
    },
  },
  mounted() {
    this.$emit('getUserAccountsFromDb');
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
body {
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  background: rgba(71, 147, 227, 1);
}
h2 {
  text-align: left;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  padding: 30px 0;
} /* Table Styles */
.table-wrapper {
  margin: 10px 70px 70px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}
.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}
.fl-table td,
.fl-table th {
  text-align: left;
  padding: 8px;
}
.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}
.fl-table thead th {
  color: #ffffff;
  background: #4fc3a1;
}
.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}
.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

#signature {
  width: 300px;
  border-bottom: 1px solid black;
  height: 100px;
  margin-left: 100px;
}

.signaturetext {
  width: 300px;
  height: 30px;
  margin-left: 100px;
}
</style>
