<template>
  <div></div>
  <dialog open>
    <header>
      <slot name="header"
        ><h2>{{ title }}</h2></slot
      >
    </header>
    <section>
      <slot name="main"></slot>
    </section>
    <menu><slot name="actions"></slot></menu>
  </dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}
header {
  background-color: #ff0000;
  color: white;
  width: 100%;
  padding: 1rem;
  text-align: center;
}
header h2 {
  margin: 0;
}

section {
  width: 90%;
  padding: 1rem;
  text-align: center;
}
menu {
  text-align: center;
}
</style>
