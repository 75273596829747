<template>
  <!-- Team Section -->
  <div class="w3-container" style="padding: 128px 16px" id="team">
    <!--     <h3 class="w3-center">Ampumapäiväkirja</h3>
    <p class="w3-center w3-large">Tapahtumat</p> -->
    <div class="w3-row-padding" style="margin-top: 10px">
      <div
        style="width: 100%"
        v-for="entry in entries"
        :key="entry.id"
        class="w3-col l3 m6 w3-margin-bottom"
      >
        <div class="w3-card">
          <div class="w3-container">
            <h3>{{ formatDate(entry.pvm) }}</h3>
            <h3>{{ entry.rata }}</h3>
            <p>{{ entry.ase }}</p>
            <p>{{ entry.laukaukset }} laukausta</p>
            <p>{{ entry.muuta }}</p>
            <p>
              <!-- <button class="button-finetune w3-button w3-light-grey w3-block"> -->
              <base-button @click="passToBeEditedEntry(entry._id)"
                ><i class="fa fa-pencil"></i> MUOKKAA
              </base-button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './UI/BaseButton.vue';
//import BaseCard from './UI/BaseCard.vue'
export default {
  components: { BaseButton },
  emits: ['passToBeEditedEntryId', 'getEntriesFromDb'],
  props: ['entries'],
  methods: {
    passToBeEditedEntry(id) {
      this.$emit('passToBeEditedEntryId', id);
    },
    formatDate(dateString) {
      const dateObj = new Date(dateString);
      const formattedDateString =
        dateObj.getDate() +
        '.' +
        (dateObj.getMonth() + 1) +
        '.' +
        dateObj.getFullYear();
      return formattedDateString;
    },
  },
  mounted() {
    this.$emit('getEntriesFromDb');
  },
};
</script>

<style scoped></style>
